@import '~@astrid/components/dist/scss/utils';

.root {
  display: block;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 0 15px;
  min-height: 100%;

  h1 {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 496px;
    align-items: center;
    > button {
      margin-top: 20px;
      min-width: 154px;
    }
  }
}

.logo {
  color: #000;
  height: 30px;
  width: 118px;
}

.leftGraphic {
  position: absolute;
  bottom: 56px;
  width: 550px;
  left: -66px;
  z-index: -1;
  @include breakpoint(sm) {
    display: none;
  }
}

.rightGraphic {
  position: absolute;
  right: 80px;
  bottom: 56px;
  width: 550px;
  z-index: -1;
  @include breakpoint(sm) {
    right: auto;
    left: auto;
    width: 80%;
    bottom: 20px;
  }
}

.header {
  padding: 16px 0 100px 0;
  display: flex;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
